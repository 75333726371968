/** @jsx jsx */
import { Link } from "gatsby"
import { Flex, Heading, jsx, Link as TLink } from "theme-ui"
import useBlogConfig from "../hooks/use-blog-config"
import replaceSlashes from "../utils/replaceSlashes"
import Layout from "./layout"
import Listing from "./listing"
import SEO from "./seo"

const BlogComponent = ({ posts }) => {
  const { tagsPath, basePath } = useBlogConfig()

  return (
    <Layout>
      <SEO title="Posts" />
      <Flex
        sx={{
          alignItems: `center`,
          justifyContent: `space-between`,
          flexFlow: `wrap`,
        }}
      >
        <Heading variant="styles.h2">Posts</Heading>
        <TLink
          as={Link}
          sx={{ variant: `links.secondary` }}
          to={replaceSlashes(`/${basePath}/${tagsPath}`)}
        >
          View all tags
        </TLink>
      </Flex>
      <Listing posts={posts} sx={{ mt: [4, 5] }} />
    </Layout>
  )
}

const Blog = ({ data }) => {
  const { allPost } = data

  return <BlogComponent posts={allPost.nodes} />
}

export default Blog
